import { Cover } from "@src/queries/cover";
import { BaseTreeData } from "./schedules";
import { sortArrayOfObject } from "./sort";

export const getTreeData = (covers: Cover[]): BaseTreeData[] => {
  const treeData: BaseTreeData[] = []

  // iterate over locations and start making tree data
  covers.forEach(cover => {
    if (cover.status > 0) {
      treeData.push({
        title: `${cover.orgName} - ${cover.coverKey}`,
        value: `${cover.coverKey}`,
        key: `${cover.coverKey}`,
      })
    }
  });

  // sort
  treeData.sort(sortArrayOfObject("title"));

  return treeData
}
