import React from "react"
import { Router } from "@reach/router"

import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import SelectTemplate from "./selectTemplate/"
import TemplateDetails from "./templateDetails"
import TemplateEditor from "./templateEditor"

const TemplateManagement = (): React.ReactElement => {
  return (
    <Router>
      <SelectTemplate path={navigationRoutes[PageOptions.TEMPLATEMANAGEMENT]} />
      <TemplateDetails
        path={navigationRoutes[PageOptions.TEMPLATECREATEDETAILS]}
      />
      <TemplateDetails
        path={navigationRoutes[PageOptions.TEMPLATEDUPLICATEDETAILS]}
      />
      <TemplateDetails
        path={navigationRoutes[PageOptions.TEMPLATEEDITDETAILS]}
      />
      <TemplateEditor path={navigationRoutes[PageOptions.TEMPLATEEDITOR]} />
    </Router>
  )
}

export default TemplateManagement
