import React from "react"

import { Template } from "@src/queries/template"

interface TemplateEmailPreviewContextProps {
  template: Template | null
  setTemplate?: React.Dispatch<React.SetStateAction<Template | null>>
}

const TemplateEmailPreviewContext = React.createContext<TemplateEmailPreviewContextProps>(
  {
    template: null,
  }
)

export default TemplateEmailPreviewContext
