import React from "react"

import Layout from "@src/components/layout"
import Templates from "@src/sections/templates"

const Index = (): React.ReactElement => {
  return (
    <Layout>
      <Templates />
    </Layout>
  )
}

export default Index
