const blank = `<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff;" data-node="text">&nbsp;</p>
<table class="mceNonEditable" style="font-family: Arial, Helvetica, sans-serif; background-color: #edeced; margin: 0px;" border="0" width="100%" cellspacing="0" cellpadding="10" align="center" data-node="column">
<tbody>
<tr>
<td>
<table style="max-width: 520px; margin: auto;" border="0" width="100%" cellspacing="0" cellpadding="0" data-node="column">
<tbody>
<tr>
<td align="center" valign="middle" width="30%"><img class="mceEditable" style="display: inline-block; height: auto; width: 60%; padding: 0px; border: 0px solid #000000; vertical-align: middle;" src="https://storage.googleapis.com/eblast-production/t_primary_vision_2.png" /></td>
<td align="center" valign="middle" width="2%"><span style="border-right: 1px solid #2c3539; margin: 0px 2.7%; vertical-align: middle; height: 38px; display: inline-block;">&nbsp;</span></td>
<td align="right"><a style="padding: 0px 4px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: normal; color: #000000; text-decoration: underline; background-color: #edeced;" href="https://eblast-dot-tough-zoo-144917.uc.r.appspot.com/unsubscribe?PatientID={{ PATIENT.ID }}&amp;CoverKey={{ PRACTICE.COVERKEY }}&amp;CampaignID={{ CAMPAIGN.ID }}" target="_blank" rel="noopener">Unsubscribe</a></td>
<td align="center" width="2%"><span style="border-right: 1px solid #2c3539; margin: 10px;">&nbsp;</span></td>
<td align="left"><a style="padding: 0px 4px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: normal; color: #000000; text-decoration: underline; background-color: #edeced;" href="https://4patient.care/assets/4pc-privacy-statement.pdf" target="_blank" rel="noopener">Privacy Policy</a></td>
</tr>
<tr></tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>`

export default blank