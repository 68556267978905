import React from "react"
import tw from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { RightOutlined } from "@ant-design/icons"
import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"

import { Localization } from "@src/localization"
import CampaignNavigation, {
  NavigationWidth,
} from "@src/components/campaign/navigation"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import ErrorComponent from "@src/components/ui/error"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import {
  GET_TEMPLATES,
  Template,
  TemplateCategory,
} from "@src/queries/template"
import TemplatesComponent from "@src/sections/createCampaign/selectTemplate/templates"
import { GET_USER, User } from "@src/queries/user"
import { Tooltip } from "antd"
import {
  canManageTemplates,
  canUserEditThisTemplate,
} from "@src/utils/accessControl"

// eslint-disable-next-line no-empty-pattern
const SelectTemplate = ({}: RouteComponentProps): React.ReactElement => {
  // page content
  const createContent = React.useContext(Localization).createTemplate

  // get templates
  const getTemplatesResp = useQuery(GET_TEMPLATES)

  // get user
  const getUserResp = useQuery(GET_USER)

  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = React.useState<null | Template>(null)

  // loading data
  if (getTemplatesResp.loading || getUserResp.loading) {
    return <Loading withOverlay={true} />
  }

  // show error page
  if (getTemplatesResp.error || getUserResp.error)
    return (
      <ErrorComponent msg="There is an error fetching data. Please refresh your page or try again later" />
    )

  // user
  const user: User = getUserResp && getUserResp.data && getUserResp.data.user

  // user can't manage templates
  if (user && !canManageTemplates(user))
    return (
      <ErrorComponent msg="Looks like you don't have access for managing templates" />
    )

  // templates
  const templates: Template[] = getTemplatesResp.data
    ? getTemplatesResp.data.templates
    : []

  const editableGlobalTemplates = templates.filter(t =>
    [TemplateCategory.global, TemplateCategory.smartbook].includes(t.category)
  )

  const editablLocalTemplates = templates.filter(t =>
    [TemplateCategory.local].includes(t.category)
  )

  // can user edit global template
  const canEditTemplate =
    selectedTemplate && canUserEditThisTemplate(selectedTemplate, user)

  // message if user can't edit global template
  const editTooltipMsg =
    selectedTemplate && !canEditTemplate
      ? "This template is shared across multiple covers, so it cannot be edited. You may first, DUPLICATE a template and then edit the duplicate."
      : ""

  return (
    <div css={[tw`bg-white`]}>
      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation width={NavigationWidth.FiveSeven} />

        {/* templates */}
        <TemplatesComponent
          globalTemplates={editableGlobalTemplates}
          localTemplates={editablLocalTemplates}
          selectedTemplate={selectedTemplate}
          onSelectTemplate={setSelectedTemplate}
        />
      </CustomContainer>

      {/* bottom navigation */}
      <CustomContainer
        customCss={tw`flex py-4 border-t border-gray-400 justify-end w-full`}
      >
        {/* duplicate template */}
        <CustomButton
          color={ButtonColors.blue}
          customCss={tw`mr-8`}
          onClick={() => {
            selectedTemplate &&
              navigate(
                createUrl(
                  navigationRoutes[PageOptions.TEMPLATEDUPLICATEDETAILS],
                  {
                    templateId: selectedTemplate.id,
                  }
                )
              )
          }}
          disabled={!selectedTemplate}
        >
          {createContent.duplicateTemplate}
        </CustomButton>

        {/* edit template */}
        <Tooltip
          placement="topRight"
          title={editTooltipMsg}
          overlayStyle={{
            fontSize: "0.8rem",
          }}
          arrowPointAtCenter
        >
          <div>
            <CustomButton
              color={ButtonColors.orange}
              customCss={tw``}
              onClick={() => {
                selectedTemplate &&
                  navigate(
                    createUrl(
                      navigationRoutes[PageOptions.TEMPLATEEDITDETAILS],
                      {
                        templateId: selectedTemplate.id,
                      }
                    )
                  )
              }}
              disabled={!canEditTemplate}
            >
              {createContent.editTemplate}
              <RightOutlined tw="ml-2" />
            </CustomButton>
          </div>
        </Tooltip>
      </CustomContainer>
    </div>
  )
}

export default SelectTemplate
