import React from "react"
import { RouteComponentProps } from "@reach/router"

import TemplateEmailPreviewContext from "@src/context/templateEmailPreviewContext"
import EditorWrapper from "@src/components/editor/wrapper"
import { Template } from "@src/queries/template"
import TemplateEditorComponent from "./editor"

interface TemplateEditorProps {
  templateId?: number
}

const TemplateEditor = ({
  templateId,
}: TemplateEditorProps & RouteComponentProps): React.ReactElement => {
  const [template, setTemplate] = React.useState<Template | null>(null)

  return (
    <TemplateEmailPreviewContext.Provider
      value={{
        template,
        setTemplate,
      }}
    >
      <EditorWrapper>
        {templateId && <TemplateEditorComponent templateId={templateId} />}
      </EditorWrapper>
    </TemplateEmailPreviewContext.Provider>
  )
}

export default TemplateEditor
