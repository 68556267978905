import React from "react"
import tw, { css } from "twin.macro"
import { useQuery, useMutation } from "@apollo/client"
import ReactTooltip from "react-tooltip"
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react"
import { navigate } from "gatsby"
import { useToasts } from "react-toast-notifications"

import { Localization } from "@src/localization"
import CampaignNavigation, {
  NavigationWidth,
} from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import EditorContext from "@src/context/editorContext"
import { TemplatePreviewModal } from "@src/components/template/preview"
import { GET_TEMPLATE, Template, UPDATE_TEMPLATE } from "@src/queries/template"
import ToolBar from "@src/sections/editCampaign/editor/toolBar"
import EditorTabsComponent from "@src/sections/editCampaign/editor/EditorTabs"
import TemplateEmailPreviewContext from "@src/context/templateEmailPreviewContext"
import { createUrl } from "@src/utils/createUrl"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import config from "@src/config"
import { GET_USER, User } from "@src/queries/user"
import { canUserEditThisTemplate } from "@src/utils/accessControl"
import ErrorComponent from "@src/components/ui/error"

interface TemplateEditorComponentProps {
  templateId: number
}

const TemplateEditorComponent = ({
  templateId,
}: TemplateEditorComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const editorContent = React.useContext(Localization).editor

  // tinymce editor
  const {
    setMceEditor,
    setSelectedNode,
    showPreview,
    setShowPreview,
    isDesktopPreview,
    setIsDesktopPreview,
  } = React.useContext(EditorContext)

  // template for preview
  const {
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(TemplateEmailPreviewContext)

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
  })

  // template data
  const template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  // get user
  const getUserResp = useQuery(GET_USER)
  // user
  const user: User | null =
    (getUserResp && getUserResp.data && (getUserResp.data.user as User)) || null  

  React.useEffect(() => {
    if (template) {
      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [getTemplateResp])

  // on form submit
  const onSubmit = async () => {
    try {
      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...previewTemplate,
            coverKeys:
              previewTemplate &&
              previewTemplate.coverTemplates &&
              previewTemplate.coverTemplates.length > 0
                ? previewTemplate.coverTemplates.map(c => `${c.coverKey}`)
                : [],
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // show loader
  if (getTemplateResp.loading || getUserResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  // user can't edit this template
  if (user && template && !canUserEditThisTemplate(template, user))
    return (
      <ErrorComponent msg="Looks like you don't have access for editing this template" />
    )

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateTemplateResp.loading && <Loading withOverlay={true} />}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation width={NavigationWidth.FiveSeven} />

        {/* tooltip */}
        <ReactTooltip
          id="selectDetailsFieldTip"
          place="top"
          effect="solid"
          borderColor="#000"
        />

        {/* page description */}
        <div tw="flex text-black text-xs">{editorContent.pageDescription}</div>

        {/* template preview */}
        <TemplatePreviewModal
          templateContent={previewTemplate.templateContent}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          isDesktopPreview={isDesktopPreview}
          setIsDesktopPreview={setIsDesktopPreview}
        />

        {/* email editing */}
        <div tw="flex mt-3 items-end">
          {/* left container */}
          <LeftContainer
            customCss={[
              tw`w-8/12! p-0! border border-b-0 border-gray-400 mr-4! relative`,
              css`
                height: 510px;
                & .mce-content-body {
                  height: 100%;
                  width: 100%;
                  overflow: auto;
                }
                & .mce-content-body:focus {
                  outline-color: transparent !important;
                }
                .tox .tox-dialog__header {
                  background-color: #ff0000 !important;
                }
              `,
            ]}
          >
            <div tw="p-2 w-full h-full">
              <TinyMCEEditor
                apiKey={config.tinyMceApiKey}
                inline={true}
                initialValue={previewTemplate.templateContent}
                init={config.tinyeMceConfig}
                onEditorChange={(content: any) => {
                  setPreviewTemplate &&
                    setPreviewTemplate({
                      ...previewTemplate,
                      templateContent: content,
                    })
                }}
                onInit={(_event: any, editor: any) => {
                  setMceEditor && setMceEditor(editor)
                }}
                onNodeChange={(event: any) => {
                  // console.log("event", event, typeof event.element)
                  setSelectedNode &&
                    event.element &&
                    setSelectedNode(event.element)
                }}
                onObjectResized={(event: any) => {
                  if (event.target.nodeName == "IMG") {
                    // image width
                    const imgWidth = event.target.style.width

                    if (imgWidth && imgWidth.includes("px")) {
                      const widthInPercent = `${(
                        (parseInt(imgWidth) * 100) /
                        event.target.parentElement.clientWidth
                      ).toFixed(0)}%`

                      event.target.setAttribute("data-mce-style", "")
                      event.target.style.width = widthInPercent
                      event.target.style.height = "auto"
                    }
                  }
                }}
              />
            </div>

            {/* quick toolbar */}
            <ToolBar />
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading={editorContent.heading}
            descriptionCss={tw`text-2xs!`}
            description={editorContent.description}
            customCss={[
              tw`w-4/12! overflow-auto`,
              css`
                height: 510px;
              `,
            ]}
          >
            <EditorTabsComponent coverKey={(getUserResp.data.user as User).coverKey} />
          </RightContainer>
        </div>
      </CustomContainer>

      {/* action bar */}
      <ActionBar
        withBoundaries={true}
        leftContainerCss={tw`w-8/12! justify-between! pr-4!`}
        rightContainerCss={tw`w-4/12!`}
        backBtnOnClick={() =>
          navigate(
            createUrl(navigationRoutes[PageOptions.TEMPLATEEDITDETAILS], {
              templateId,
            })
          )
        }
        saveBtnText="Update Template"
        saveBtnOnClick={async () => {
          await onSubmit()
        }}
        nextBtnText="Start New Campaign"
        nextBtnOnClick={async () => {
          await onSubmit()
          navigate(navigationRoutes[PageOptions.PICKTEMPLATE])
        }}
      />
    </div>
  )
}

export default TemplateEditorComponent
