import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps, useMatch } from "@reach/router"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"
import { Form, Input, Select, TreeSelect } from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import { Localization } from "@src/localization"
import CampaignNavigation, {
  NavigationWidth,
} from "@src/components/campaign/navigation"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomContainer from "@src/components/customContainer"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import TemplatePreview from "@src/components/template/preview"
import InputLabel from "@src/components/ui/inputLabel"
import CustomToolTip from "@src/components/ui/customToolTip"
import {
  CreateTemplateInput,
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  GET_TEMPLATES,
  Template,
  TemplateCategory,
  templateTypeOptions,
  UPDATE_TEMPLATE,
} from "@src/queries/template"
import { Cover, GET_COVERS } from "@src/queries/cover"
import Loading from "@src/components/loading"
import { getTreeData } from "@src/utils/covers"
import { createUrl } from "@src/utils/createUrl"
import { GET_USER, User } from "@src/queries/user"
import {
  canUserEditThisTemplate,
  is4PCSupportHero,
} from "@src/utils/accessControl"
import ErrorComponent from "@src/components/ui/error"

import blank from '@src/sections/creator/blank'

enum FlowType {
  Create = "Create",
  Duplicate = "Duplicate",
  Edit = "Edit",
}

interface TemplateDetailsProps {
  templateId?: number
}

// eslint-disable-next-line no-empty-pattern
const TemplateDetails = ({
  templateId,
}: TemplateDetailsProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // find flow type
  const isCreateFlow = useMatch(
    navigationRoutes[PageOptions.TEMPLATECREATEDETAILS]
  )
  const isDuplicateFlow = useMatch(
    navigationRoutes[PageOptions.TEMPLATEDUPLICATEDETAILS]
  )
  const flowType = isCreateFlow
    ? FlowType.Create
    : isDuplicateFlow
    ? FlowType.Duplicate
    : FlowType.Edit

  // page content
  const detailsContent = React.useContext(Localization).templateDetails

  // form
  const [form] = Form.useForm()

  // selected template type
  const [
    templateType,
    setTemplateType,
  ] = React.useState<TemplateCategory | null>(null)

  // create template hook
  const [createTemplateMutation, createTemplateResp] = useMutation(
    CREATE_TEMPLATE
  )

  // update template hook
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // get covers
  const getCoversResp = useQuery(GET_COVERS)
  const covers =
    getCoversResp &&
    getCoversResp.data &&
    (getCoversResp.data.covers as Cover[])

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: {
      templateId,
    },
    skip: !templateId && flowType === FlowType.Create,
  })
  const template: Template | null =
    (getTemplateResp &&
      getTemplateResp.data &&
      (getTemplateResp.data.template as Template)) ||
    null

  // get user
  const getUserResp = useQuery(GET_USER)
  // user
  const user: User | null =
    (getUserResp && getUserResp.data && (getUserResp.data.user as User)) || null

  // is 4pc support user
  const is4PCSupportUser = user && is4PCSupportHero(user)

  // set template type
  React.useEffect(() => {
    if (template) {
      setTemplateType(template.category)
    }
  }, [getTemplateResp])

  // loading user or template
  if (
    getCoversResp.loading ||
    getUserResp.loading ||
    (flowType !== FlowType.Create && getTemplateResp.loading)
  ) {
    return <Loading withOverlay={true} />
  }

  // user can't edit this template
  if (
    user &&
    template &&
    flowType === FlowType.Edit &&
    !canUserEditThisTemplate(template, user)
  )
    return (
      <ErrorComponent msg="Looks like you don't have access for editing this template" />
    )

  // function to create a template
  const createOrUpdateTemplate = async (): Promise<number> => {
    try {
      if (!user) throw new Error("User not found!")

      // values
      const values = await form.validateFields()

      // create campaign and add template to that
      const body: CreateTemplateInput = {
        description: template ? template.description : "",
        templateContent: template ? template.templateContent : blank,
        hasLockedSections: template ? template.hasLockedSections : true,
        name: values.name,
        category: is4PCSupportUser ? values.category : TemplateCategory.local,
        coverKeys: is4PCSupportUser
          ? values.category === TemplateCategory.local
            ? values.coverKeys
            : []
          : [user.coverKey],
      }

      // template id
      let templateIdToUse

      if (flowType !== FlowType.Edit) {
        // create template
        const createdTemplate = await createTemplateMutation({
          variables: {
            body,
          },
          refetchQueries: [
            {
              query: GET_TEMPLATES,
            },
          ],
        })

        templateIdToUse = createdTemplate.data.template.id
      } else {
        // update template
        templateIdToUse = templateId

        // update template
        await updateTemplateMutation({
          variables: {
            templateId: templateIdToUse,
            body,
          },
        })
      }

      // created campaign
      return templateIdToUse
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // on form submit
  const onSubmit = async () => {
    try {
      // create or update template
      const templateIdToUse = await createOrUpdateTemplate()

      // navigate to next screen
      templateIdToUse &&
        navigate(
          createUrl(navigationRoutes[PageOptions.TEMPLATEEDITOR], {
            templateId: templateIdToUse,
          })
        )
    } catch (e) {
      console.log("error", e)
    }
  }

  // tree data
  const treeData = getTreeData(covers)

  return (
    <div css={[tw`bg-white`]}>
      {(createTemplateResp.loading || updateTemplateResp.loading) && (
        <Loading withOverlay={true} />
      )}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation width={NavigationWidth.FiveSeven} />

        {/* email editing */}
        <div tw="flex mt-3 items-end justify-center">
          {/* left container */}
          <LeftContainer
            customCss={[
              tw`w-5/12! p-0! border border-b-0 border-gray-400 mr-4! items-start!`,
              css`
                & .mce-content-body {
                  height: 100%;
                  width: 100%;
                  overflow: auto;
                }
                & .mce-content-body:focus {
                  outline-color: transparent !important;
                }
                .tox .tox-dialog__header {
                  background-color: #ff0000 !important;
                }
              `,
            ]}
          >
            <div tw="flex w-full justify-center bg-white border-b border-gray-400">
              {template ? template.name : "-"}
            </div>
            <div
              tw="w-full"
              css={css`
                height: 485px;
              `}
            >
              <TemplatePreview
                templateContent={template ? template.templateContent : ""}
                scale={0.5}
                scrollable
                clickable={false}
              />
            </div>
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading={detailsContent.heading}
            description={detailsContent.description}
            customCss={[
              tw`w-5/12! overflow-auto flex flex-col relative`,
              css`
                height: 510px;
                padding-bottom: 60px;
                & .ant-select-selector,
                & .ant-input,
                & .ant-input-number,
                & .ant-input-affix-wrapper,
                & .ant-picker,
                & .ant-select-selector {
                  border-radius: 50px !important;
                  font-size: 0.75rem;
                }
                & .ant-row.ant-form-item {
                  margin-bottom: 0px !important;
                }
              `,
            ]}
          >
            <div tw="flex flex-col flex-grow border-t border-gray-400">
              <Form
                name="templateDetails"
                form={form}
                initialValues={
                  template
                    ? {
                        name: `${template.name}${
                          flowType === FlowType.Duplicate ? " - copy" : ""
                        }`,
                        category: template.category,
                        coverKeys: template.coverTemplates
                          ? template.coverTemplates.map(c => `${c.coverKey}`)
                          : [],
                      }
                    : {}
                }
                css={css`
                  overflow: auto;
                  max-height: 334px;
                `}
              >
                {/* name */}
                <div tw="flex flex-col py-3 mx-4">
                  <div tw="flex w-full flex-row items-center">
                    <InputLabel isRequired customCss={tw`w-4/12 font-bold`}>
                      {detailsContent.templateName}
                    </InputLabel>
                    <div tw="flex items-center w-8/12">
                      <Form.Item
                        name="name"
                        tw="w-11/12 mr-2"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please input Template Name!",
                          },
                          {
                            whitespace: true,
                            message: "Please input Template Name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder={detailsContent.templateNamePlaceholder}
                        />
                      </Form.Item>
                      <CustomToolTip
                        title={detailsContent.templateNameInfo}
                        customCss={tw`pt-0!`}
                      />
                    </div>
                  </div>
                </div>

                {/* only 4pc support user can access */}
                {is4PCSupportUser && (
                  <>
                    {/* template type */}
                    <div tw="flex border-t border-gray-400 flex-col py-3 mx-4">
                      <div tw="flex w-full flex-row items-center">
                        <InputLabel isRequired customCss={tw`w-4/12 font-bold`}>
                          {detailsContent.templateType}
                        </InputLabel>
                        <div tw="flex items-center w-8/12">
                          <Form.Item
                            name="category"
                            tw="w-11/12 mr-2"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Please select Template Type!",
                              },
                            ]}
                          >
                            <Select
                              placeholder={
                                detailsContent.templateTypePlaceholder
                              }
                              onChange={v =>
                                setTemplateType(v as TemplateCategory)
                              }
                            >
                              {templateTypeOptions.map(oneOption => (
                                <Select.Option
                                  key={oneOption.value}
                                  value={oneOption.value}
                                >
                                  {oneOption.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <CustomToolTip
                            title={detailsContent.templateTypeInfo}
                            customCss={tw`pt-0!`}
                          />
                        </div>
                      </div>

                      {/* pick covers */}
                      {templateType === TemplateCategory.local && (
                        <div tw="flex w-full flex-row items-center mt-2">
                          <InputLabel
                            isRequired
                            customCss={tw`w-4/12 text-2xs! font-bold`}
                          >
                            {detailsContent.selectCoversLabel}
                          </InputLabel>
                          <div tw="flex items-center w-8/12">
                            <Form.Item
                              name="coverKeys"
                              tw="w-11/12 mr-2"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Please input!",
                                },
                              ]}
                            >
                              <TreeSelect
                                treeData={treeData}
                                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                placeholder={
                                  detailsContent.selectCoversPlaceholder
                                }
                                treeCheckable
                                showSearch
                                multiple
                                treeNodeFilterProp="title"
                                treeNodeLabelProp="title"
                                maxTagCount={6}
                                listHeight={350}
                              />
                            </Form.Item>
                            <CustomToolTip
                              title={detailsContent.selectCoversInfo}
                              customCss={tw`pt-0!`}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Form>

              {/* bottom navigation */}
              <div
                tw="flex py-3 border-t border-gray-400 justify-around w-full absolute bottom-0 bg-white"
                css={css`
                  height: 60px;
                `}
              >
                <CustomButton
                  color={ButtonColors.white}
                  onClick={() =>
                    navigate(navigationRoutes[PageOptions.TEMPLATEMANAGEMENT])
                  }
                >
                  <LeftOutlined tw="mr-2" />
                  {detailsContent.back}
                </CustomButton>

                <CustomButton
                  color={ButtonColors.orange}
                  onClick={() => onSubmit()}
                >
                  {flowType === FlowType.Create &&
                    detailsContent.createTemplateBtn}
                  {flowType === FlowType.Duplicate &&
                    detailsContent.createDuplicateTemplateBtn}
                  {flowType === FlowType.Edit && detailsContent.editTemplateBtn}
                  <RightOutlined tw="ml-2" />
                </CustomButton>
              </div>
            </div>
          </RightContainer>
        </div>
      </CustomContainer>
    </div>
  )
}

export default TemplateDetails
